import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getSession } from "next-auth/react";

const apiGateway = process.env.NEXT_PUBLIC_API_GATEWAY;
const apiPath = "expert";

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiGateway}/${apiPath}`,
  prepareHeaders: async (headers) => {
    const session = await getSession();

    // Attaches Authorization header to all API requests.
    headers.set("Authorization", `Bearer ${session?.idToken}`);

    return headers;
  },
});

// Initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Patient"],
});
